
import { setPagination } from "@/utils/pagination";
import { computed, defineComponent, PropType } from "vue";
import { DataTableHeader } from "./types";

export default defineComponent({
  props: {
    headers: {
      type: Array as PropType<DataTableHeader[]>,
      default: [],
    },
    items: {
      type: Array as PropType<any[]>,
      default: [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    customWitdh: {
      type: String,
      default: "100%",
    },
    loadingDivId: {
      type: String,
      default: "",
      required: true,
    },

    // START === For Pagination ====================================================
    hidePagination: {
      type: Boolean,
      default: false,
    },
    page: {
      // for current page
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    totalData: {
      type: Number,
      default: 1,
    },
    // END === For Pagination ======================================================
  },
  setup(props, { emit }) {
    const randomNumber = Math.floor(Math.random() * 1000000000);
    const dataTableHeader = `data-table-header-${randomNumber}`;
    const dataTableItem = `data-table-item-${randomNumber}`;

    const keyHeaders = props.headers.map(
      (header: DataTableHeader) => header.value
    );

    const updatedItems = computed(() => {
      const items = props.items.map((item: any, idx: number) => ({
        ...item,
        [keyHeaders[idx]]: item?.[keyHeaders[idx]],
      }));

      return items;
    });

    // START === Functions for Pagination ==========================================
    const setupPageSize = computed({
      get: () => props.pageSize,
      set: (value) => emit("update:page-size", value),
    });

    const listPages = computed(() => {
      const page = props.page;
      const pageSize = props.pageSize;
      const totalData = props.totalData;

      const { pages } = setPagination(totalData, page, pageSize, 3);
      return pages;
    });

    const totalPages = computed(() => {
      const page = props.page;
      const pageSize = props.pageSize;
      const totalData = props.totalData;

      const paginate = setPagination(totalData, page, pageSize, 3);
      return paginate.totalPages;
    });

    const goToPage = (p: number) => {
      emit("update:page", p);
    };

    const beforePage = () => {
      emit("update:page", props.page - 1);
    };

    const nextPage = () => {
      emit("update:page", props.page + 1);
    };

    const lastPage = () => {
      emit("update:page", totalPages.value);
    };
    // END === Functions for Pagination ============================================

    return {
      dataTableHeader,
      dataTableItem,

      keyHeaders,
      updatedItems,

      setupPageSize,
      goToPage,
      beforePage,
      nextPage,
      lastPage,
      listPages,
      totalPages,
    };
  },
});
